var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(_vm._s(_vm.appName))])]),_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" Forget the Password？ ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please enter your email and press the Send Verification Code button, we will send you the verification code ")]),_c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-form',{staticClass:"auth-form mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.resetPasswordForm.apply(null, arguments)}}},[_c('b-form-group',{staticClass:"required"},[_c('label',[_vm._v("Email")]),_c('validation-provider',{attrs:{"name":"Email：","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',[_c('b-form-input',{attrs:{"id":"forgot-password-email","state":errors.length > 0 ? false : null,"name":"forgot-password-email","placeholder":"Enter Email"},model:{value:(_vm.resetData.email),callback:function ($$v) {_vm.$set(_vm.resetData, "email", $$v)},expression:"resetData.email"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-button',{staticClass:"p-50",attrs:{"variant":"primary","disabled":!_vm.resetData.email},on:{"click":_vm.sendVertificationCode}},[_vm._v(" Send Verification Code ")])],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required mt-1",attrs:{"label":"New Password："}},[_c('b-form-input',{attrs:{"id":"password","type":"password","placeholder":"New Password","state":errors.length > 0 ? false : null},model:{value:(_vm.resetData.password),callback:function ($$v) {_vm.$set(_vm.resetData, "password", $$v)},expression:"resetData.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Confirm New Password","vid":"c_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Confirm New Password："}},[_c('b-form-input',{attrs:{"id":"c_password","type":"password","placeholder":"Confirm New Password","state":errors.length > 0 ? false : null},model:{value:(_vm.resetData.c_password),callback:function ($$v) {_vm.$set(_vm.resetData, "c_password", $$v)},expression:"resetData.c_password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Verification Code","vid":"vaildate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{staticClass:"required",attrs:{"label":"Verification Code："}},[_c('b-form-input',{attrs:{"id":"vaildate","placeholder":"Verification Code","state":errors.length > 0 ? false : null},model:{value:(_vm.resetData.vertification_code),callback:function ($$v) {_vm.$set(_vm.resetData, "vertification_code", $$v)},expression:"resetData.vertification_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('div',[_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" Submit ")])],1)],1)],1)],1),_c('p',{staticClass:"text-center mt-3"},[_c('b-link',{attrs:{"to":{ name: 'login' }}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" Back to Login Page ")],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }