<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Login v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <h2 class="brand-text text-primary ml-1">{{ appName }}</h2>
                </b-link>
                <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                    Forget the Password？
                </b-card-title>
                <b-card-text class="mb-2">
                    Please enter your email and press the Send Verification Code button, we will send you the
                    verification code
                </b-card-text>

                <!-- form -->

                <validation-observer ref="simpleRules">
                    <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
                        <b-form class="auth-form mt-3" @submit.prevent="resetPasswordForm">
                            <b-form-group class="required">
                                <label>Email</label>
                                <validation-provider #default="{ errors }" name="Email：" rules="required|email">
                                    <b-input-group>
                                        <b-form-input id="forgot-password-email" v-model="resetData.email"
                                            :state="errors.length > 0 ? false : null" name="forgot-password-email"
                                            placeholder="Enter Email" />
                                        <b-input-group-append is-text>
                                            <b-button variant="primary" class="p-50" @click="sendVertificationCode" :disabled="!resetData.email">
                                                Send Verification Code
                                            </b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>

                                <validation-provider #default="{ errors }" name="Password" vid="password"
                                    rules="required">
                                    <b-form-group class="required mt-1" label="New Password：">
                                        <b-form-input id="password" type="password" placeholder="New Password"
                                            v-model="resetData.password" :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>

                                <validation-provider #default="{ errors }" name="Confirm New Password" vid="c_password"
                                    rules="required">
                                    <b-form-group class="required" label="Confirm New Password：">
                                        <b-form-input id="c_password" type="password" placeholder="Confirm New Password"
                                            v-model="resetData.c_password" :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                                <validation-provider #default="{ errors }" name="Verification Code" vid="vaildate"
                                    rules="required">
                                    <b-form-group class="required" label="Verification Code：">
                                        <b-form-input id="vaildate" placeholder="Verification Code"
                                            v-model="resetData.vertification_code"
                                            :state="errors.length > 0 ? false : null" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </b-form-group>
                                </validation-provider>
                            </b-form-group>
                            <div>
                                <b-button type="submit" variant="primary" block class="">
                                    Submit
                                </b-button>
                            </div>
                        </b-form>
                    </b-overlay>
                </validation-observer>

                <p class="text-center mt-3">
                    <b-link :to="{ name: 'login' }">
                        <feather-icon icon="ArrowLeftIcon" /> Back to Login Page
                    </b-link>
                </p>
                <!-- /Forgot password-->
                <!--
          <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>
          -->
            </b-card>
            <!-- /Login v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BCard,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";

export default {
    directives: {
        Ripple,
    },
    components: {
        // BSV
        VuexyLogo,
        BCard,
        BRow,
        BCol,
        BLink,
        BImg,
        BForm,
        BButton,
        BFormGroup,
        BFormInput,
        BCardTitle,
        BCardText,
        BInputGroup,
        BInputGroupAppend,
        BOverlay,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            userEmail: '',
            resetData: {},
            confirm_password: '',
            // validation rules
            required,
            email,
            loading: false,
        };
    },
    computed:{
        appName() {
        return process.env.VUE_APP_APP_NAME;
    },
    },
    methods: {
        resetPasswordForm() {
            this.loading = true
            axiosIns.post("/user/resetpassword_with_code", this.resetData).then((response) => {
                this.$swal({
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: "確定",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    showClass: {
                        popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                });
                this.$router.replace("/login");
                this.loading = false
            }).catch((err) => {
                console.log(err);
                this.loading = false
                this.$swal({
                    text: JSON.stringify(err.response.data.message),
                    icon: "error",
                    confirmButtonText: "確定",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    showClass: {
                        popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                });
            });
        },
        sendVertificationCode() {
            this.loading = true
            axiosIns.post("/user/sendVertificationCode", { email: this.resetData.email }).then((response) => {
                this.$swal({
                    text: response.data.message,
                    icon: "success",
                    confirmButtonText: "確定",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    showClass: {
                        popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                });
                this.loading = false
            }).catch((err) => {
                this.loading = false
                this.$swal({
                    text: JSON.stringify(err.response.data.message),
                    icon: "error",
                    confirmButtonText: "確定",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    showClass: {
                        popup: "animate__animated animate__bounceIn",
                    },
                    buttonsStyling: false,
                });
            });
        }
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";

.input-group-text {
    padding: 0rem 0.5rem;
}
</style>